import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Utilities/SEO'
import SpotifyTopArtists from '../components/Elements/SpotifyTopArtists/SpotifyTopArtists'

const Spotify = () => (
  <Layout>
    <SEO title="Spotify" />
    <h1>Spotify</h1>
    <p>
      This data is retrieved directly from Spotify. I do not own the rights to
      any of these images.
    </p>
    <SpotifyTopArtists />
  </Layout>
)

export default Spotify
